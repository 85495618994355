<template>
  <div>
     <div v-if="store.sellerGroups" class="projects_list donate_wrapper">
        <h2>Hier könnt ihr Shirts spenden</h2>
        <p class="sellers_subline">In einigen Städten haben wir feste Sammelstellen, an denen ihr eure Spenden direkt abgeben könnt:</p>
          <div class="project project_single" v-for="project in store.collectGroups" :key="project.id">        
            <!-- header -->
            <div class="project__header_content">
              <h4>{{project.organisationStadt}}</h4>
              <h2>{{project.organisationName}}</h2>
              <a class="map_link" v-bind:href="'http://maps.google.com/?q='+project.organisationStrasse+' '+project.organisationPlz+' '+project.organisationStadt" target="_blank">
              <img class="marker_icon" alt="marker" src="@/assets/images/icons/map_icon.png">
              <div class="marker_adress">
              <span v-if="project.organisationStrasse != null">{{project.organisationStrasse}}, </span>
              <span v-if="project.organisationPlz != null">{{project.organisationPlz}}, </span>
              <span v-if="project.organisationStadt != null">{{project.organisationStadt}}</span>
              </div>
              </a>
            </div>
            <a class="next-link" v-if="project.organisationUrl != null" v-bind:href="project.organisationUrl" target="_blank">Mehr Infos</a>
        </div>
      
        <h2>Bei den Ortsgruppen</h2>
        <p class="sellers_subline">
        Darüber hinaus gibt es in den folgenden Städten die Möglichkeit, Second Bandshirts, Hoodies und Co. auch direkt bei uns abzugeben. Meldet euch dazu bitte direkt bei den jeweiligen Ortsgruppen per E-Mail (siehe unten), alles weitere könnt ihr mit den Leuten vor Ort klären.
        </p>
        <div class="button_outercontainer">
          <div class="button shadow-box button_sellers" v-on:click="openPage('collectors')">
          Zu den Ortsgruppen
        </div>
        </div>
      </div>
    </div>
</template>

<script>
import store from '@/store/store.js';

export default {
  name: 'Delivery',
  components: {
  },
  data() {
    return {
      store,
    };
  },
  methods: {
    getContentData: function (dataHandle) {
      var contentModel = null;
      for(var i = 0; i < this.store.contentData.length; i++){
        if(this.store.contentData[i].pageHandle == dataHandle){
          contentModel = this.store.contentData[i];
        }
      }
      return contentModel;
    },
    openPage(url){
      this.$router.push(url);
    }
  }
}
</script>

<style lang="scss">
  .button_sellers{
    display: inline-block;
    width: 250px;
  }
  .donate_wrapper{
    text-align: center;
    margin-bottom: 30px;
  }
  .button_outercontainer{
    height: 100px;
    width: 100%;
    position: relative;
    text-align: center;
  }
</style>
